// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// FontAwesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

// Datatables BS5
@import "~datatables.net-bs5/css/dataTables.bootstrap5.css";
@import "~datatables.net-buttons-bs5/css/buttons.bootstrap5.css";

// Overrides
@import "../css/app.css";