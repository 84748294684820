

div.dataTables_wrapper div.dataTables_length {
    display: inline-block;
    margin-right: 0.5em;
}



.dt-page-jump {
    text-align: right;
    margin-right: 15px;
}

.dt-page-jump .dt-page-jump-select {
    height: 32px;
    float: right;
    margin-top: 2px;
    margin-left: 15px;
    border: 1px solid #dddddd;
    width: auto;
}




table .row-options {
    margin-top: 5px;
    padding: 2px 0 0;
    position: relative;
    left: -9999em;
}

table tr:hover td .row-options {
    position: static;
}

#main-card-image {
    left: 0px;
    position: relative;
    height: auto;
    top: 0px;
    margin: 40px;
}


.content-container {
    display: flex;
}

.content-child {
    flex: 1;
}  

/* Homepage css */

#carouselButton {
    background-color: #0d6efd;
}

#main-homepage-container {
    padding: 0px;
}



/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
  }
  body {
    margin-bottom: 60px; /* Margin bottom by footer height */
  }
  #app-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px; /* Set the fixed height of the footer here */
  }





/* Post Meta CSS 
--------------------------------------------------*/
#meta-updated-at {
    border-top: 2px solid #d5d5d5;
    width: fit-content;
}